@use 'sass:math';
@import 'fonts';
@import 'variables';
@import 'colors';
@import 'mixins';

// @import '~@ctrl/ngx-emoji-mart/picker';
.icon {display: inline-block; width: 1.5em; height: 1.4em; stroke-width: 0; stroke: currentColor; fill: currentColor;}
.icon-xl {width: 1.8em; height: 1.8em;}

/* Reset */
*{box-sizing: border-box;}
html, body {
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  color: $primary-font;
  display: flex;
  flex-direction: column;
  font-family:"futura-pt",sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  background: $background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.disabled{overflow:hidden;} 
body.disabled-modal{overflow: hidden;}
body.configurator-disabled{overflow: hidden;}
body.configurator-disabled .master-header,
body.configurator-disabled .master-header.sticky{display: none !important}
body.configurator-disabled .configurator{z-index: 110}

a:not([href]){
    cursor: pointer; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    user-select: none
}

//livechat
#chat-widget-container{z-index: 678 !important;}
b{font-family:"futura-pt-bold";}

.loader-wrap{position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.2); z-index: 25; backdrop-filter: blur(5px);}
.loader-wrap .loader-circle{@include center(both); margin: -2em 0 0 -2em}

.loader {width: 50%; height: 6px; max-width: 80px; display: block; position: absolute; z-index: 5;}
.loader::before,
.loader::after {content: '';	box-sizing: border-box;	position: absolute;}
.loader::before {	height: 100%; width: 100%; background-color: $primary; animation: loader 0.8s cubic-bezier(0, 0, 0.03, 0.9) infinite;}
@keyframes loader {
	0%, 44%, 88.1%, 100% {transform-origin: left;}
	0%, 100%, 88% {transform: scaleX(0);}
	44.1%, 88% {transform-origin: right;}
	33%, 44% {transform: scaleX(1);}
}

.wrap{width: 100%; max-width: 1100px; margin: 0 auto}

.loader-circle,
.loader-circle:after {border-radius: 50%; width: 4em; height: 4em; display: inline-block;}
.loader-circle {font-size: 10px; position: relative; text-indent: -9999em;
  border-top: 0.6em solid rgba(255, 255, 255, 0.4); border-right: 0.6em solid rgba(255, 255, 255, 0.4); border-bottom: 0.6em solid rgba(255, 255, 255, 0.4); border-left: 0.6em solid $primary;
  -webkit-transform: translateZ(0); -ms-transform: translateZ(0); transform: translateZ(0); -webkit-animation: load8 1.1s infinite linear; animation: load8 1.1s infinite linear;
}
button .loader-circle,
button .loader-circle:after{width: 20px; height: 20px;}
button .loader-circle{border-width: 4px; vertical-align: middle; margin-right:8px;}
@-webkit-keyframes load8 {
  0% {-webkit-transform: rotate(0deg); transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg); transform: rotate(360deg);}
}
@keyframes load8 {
  0% {-webkit-transform: rotate(0deg); transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg); transform: rotate(360deg);}
}

.txt-green{color:$classica2}
.txt-pink{color:$pink}
.txt-black{color:$black}
.txt-white{color:$white}

photo-dialog{position: fixed; top: 0; left: 0; width: 100%; height: 100%; display:  flex; align-items: center; justify-content: center; z-index: 99999; background: $white;}
photo-dialog .photo-dialog-navigation{position: fixed; bottom:0; left:0; width: 100%; height:60px; padding:10px; background: $white; z-index: 5; border-top: 1px solid $grey;}
photo-dialog .photo-dialog-body{display: flex; flex-direction: row; justify-content: center; align-items: center; background: $white; width:100%; height:100%; z-index: 5; @include transition(opacity .5s $global-ease); opacity: 0; overflow-x: scroll; overflow-y: hidden; scroll-snap-type: x mandatory;}
photo-dialog .photo-dialog-body img{max-width:100%; max-height: 100%; scroll-snap-align: center;}
photo-dialog.loaded .photo-dialog-body{opacity: 1;}

photo-dialog .slider-close{position: fixed; top:14px; right:14px; width: 40px;height: 40px; background: $white; border: 1px solid $border-default; border-radius:4px; z-index: 5;}

photo-dialog .scrollable{width: auto; max-width: 100%;}
photo-dialog .slider-thumb{display: inline-block; width: 40px;height: 40px; background: $white; border: 1px solid $border-default; border-radius:4px; margin: 0 4px; flex: 0 0 40px; padding: 0; overflow: hidden; vertical-align: bottom;}
photo-dialog .slider-thumb img{width: 40px; height: 40px; display: inline-block;}

photo-dialog .slider-arrow{display: inline-block; width: 40px;height: 40px; background: $white; border: 1px solid $border-default; border-radius:4px; margin: 0 4px; padding: 0;}
photo-dialog .slider-arrow-prev, photo-dialog .slider-arrow-next{position: relative; top:auto; right: auto; left: auto; opacity:1;}

photo-dialog .slider-close:before, 
photo-dialog .slider-close:after {position: absolute; top: 50%; left: 50%; width: 1px; height: 15px; background-color: $black; transform: rotate(45deg) translate(-50%, -50%); transform-origin: top left; content: '';}
photo-dialog .slider-close:after {transform: rotate(-45deg) translate(-50%, -50%);}
photo-dialog .slider-arrow-prev:before { content: "‹"; display: inline-block; font-size: 30px; line-height: 32px;} 
photo-dialog .slider-arrow-next:before { content: "›"; display: inline-block; font-size: 30px; line-height: 32px;}

/*switch component*/
.switch.rodo{font-size: 10pt; text-align: left; line-height: 1.2em;}
.switch .switch-txt{display:inline-block; width:calc(100% - 4em)}
.switch,
.switch-style,
.switch-style:before{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.switch {
  display: inline-block;
  font-size: 100%;
  min-height: 1.6em;
  line-height: 1.6em;
  position: relative;
  cursor: pointer;
  .switch-style {
    min-height: 1.6em;
    left: 0;
    background: $dark-grey;
    -webkit-border-radius: 0.8em;
    border-radius: 0.8em;
    display: inline-block;
    position: relative;
    top: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    width: 3em;
    vertical-align: top;
    margin-right: 8px;
    cursor: pointer;
    &:before{
      display: block;
      content:'';
      height: 1.4em;
      position: absolute;
      width: 1.4em;
      background-color: #fff;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      left: 0.1em;
      top: 0.1em;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
    }
  }
  &>input[type="checkbox"],
  &>input[type="radio"]{
    display:none;
    &[disabled] + .switch-style{
      cursor: not-allowed;
      background-color:$grey;
    }
    &:checked + .switch-style{
      background-color:$primary-font;
      &:before{
        left:50% !important;
      }
    }
    &:checked[disabled] + .switch-style{
      background-color: $grey;
    }
  }
  &.switch-blue>input[type="checkbox"],
  &.switch-blue>input[type="radio"]{
    &:checked + .switch-style{
      background-color: $primary-font;
    }
    &:checked[disabled] + .switch-style{
      background-color: $primary-font;
    }
  }
}

p.infobox{display: inline-block; width:100%; margin: 10px 0 !important; padding: 10px !important; font-size: 14px; font-weight: 700 !important; line-height: 22px; border: 1px solid $border-default !important; text-align: center; color: $primary-font; @include border-radius($rounded-corners-buttons);}
p.infobox.error{color: $red; border-color:$red !important; border: 1px dashed $red !important;}
p.infobox.warning{color: $orange; border-color:$orange !important; border: 1px dashed $orange !important;}
p.promo i{font-size: 70%; font-weight: 400; opacity: 0.7; color:$primary-font !important}

ul{ padding:0; margin: 0; list-style-type: none;}

.text-right{text-align: right;}
.text-left{text-align: left;}
.text-center{text-align: center;}
.w-25{width:25%;}
.w-33{width:33%}
.w-33-md{width:100%;}
.w-50{width:50%;}
.w-50-md{width:100%;}
.w-77{width:77%}
.w-100{width:100%;}
.hidex{overflow: hidden;}
.box{background: $white; width: 100%; padding: 16px; @include border-radius($rounded-corners);}
.gutter{padding-left: $primary-gutter; padding-right:  $primary-gutter;}
.gutter-v{padding-top: $primary-gutter-v; padding-bottom:$primary-gutter-v}
.gutter-v-half{padding-top: $primary-gutter-v-half; padding-bottom:$primary-gutter-v-half}
.gutter-t{padding-top: $primary-gutter-v}
.gutter-b{padding-bottom: $primary-gutter-v}
.resetlineheight{line-height: 0;}
hr{border-color: $border-default; border-style: solid; border-width: 1px 0 0 0; width: 100%;}

:focus{outline: none}
a{color:$black; text-decoration: none; cursor: pointer;}

a.back{margin-bottom: 50px; padding:4px 0 5px 40px; height:30px; color:$white; position: relative; @include transition(transform .3s $global-ease);}
a.back span{width: 30px; height: 30px; background: rgba(0,0,0,0.1); position: absolute; top:0; left:0;}
a.back span::after{position: absolute; top:7px; left:10px; width: 0; height: 0; border-style: solid; border-width: 8px 8px 8px 0; border-color: transparent #ffffff transparent transparent; content:''}
.red{color:$red} 
.label{text-transform: uppercase; font-size: 11px; font-weight: 700; padding: 5px 10px; text-transform: uppercase; @include border-radius(30px); position: relative;}
.label.blue{background:$blue; color:$white} 
.label.green{background:$green; color:$white} 
.label.amber{background:$amber; color:$white} 
.label.teal{background:$teal; color:$white} 
.label.red{background:$red; color:$white} 
.label.lime{background:$lime; color:$white} 
.label.deep-purple{background:$deep-purple; color:$white} 
.label.cyan{background:$cyan; color:$white} 
.label.deep-pink{background:$deep-pink; color:$white} 
.label.light-green{background:$light-green; color:$white} 
.label.orange{background:$orange; color:$white} 
.label.brown{background:$brown; color:$white} 
.label.blue-grey{background:$blue-grey; color:$white} 
.label.accent{background:$accent; color:$white}
.label.accent-break{background:$accent-break; color:$white}

.label span.confirmed,
.label span.unconfirmed{position: absolute; top: 50%; right:4px; width:16px; height: 16px; border-radius: 8px; margin-top:-8px; background-color: $red;}
.label span.confirmed {background-color: $green;}

.icon-phone.available{color:$green;}

.square-wrap{display: block; width: 100%; padding-bottom: 100%; position: relative;}
.horizontal-wrap{display: block; width: 100%; padding-bottom: 50%; position: relative;}
.horizontal-wrap-third{display: block; width: 100%; padding-bottom: 33.333%; position: relative;}
.horizontal-wrap-half{display: block; width: 100%; padding-bottom: 25%; position: relative;}
.wrap-content{position: absolute; top:0; left:0; width:100%; height:100%}
.wrap-content.bottom{top:auto; bottom:0; height: auto;}
/*flx*/

.mr-half{margin-right: 8px;}

.flx-row{display: flex;}
.flx-grw{flex: 1 1 auto;}
.flx-col{display: flex; flex-direction: column;}

.hide{display: none !important;}
.hide-xs{display: none;}
.hide-sm{display: none;}

.page_1278{color:$red; font-weight: 700;}

.flx-col.flx-gap-half > *,
// .flx-row.flx-gap-half > *
.flx-dynamic.flx-gap-half > *{margin-bottom: 8px;}
.flx-col.flx-gap-default > *,
// .flx-row.flx-gap-default > *,
.flx-dynamic.flx-gap-default > *{margin-bottom: 16px;}
.flx-col.flx-gap-double > *,
// .flx-row.flx-gap-double > *,
.flx-dynamic.flx-gap-double > *{margin-bottom: 32px;}
.flx-col.flx-gap-triple > *,
// .flx-row.flx-gap-triple > *,
.flx-dynamic.flx-gap-triple > *{margin-bottom: 48px;}
.flx-col.flx-gap-quadruple > *,
// .flx-row.flx-gap-quadruple > *,
.flx-dynamic.flx-gap-quadruple > *{margin-bottom: 64px;}

.flx-col.flx-gap-half > *:last-child,
.flx-col.flx-gap-default > *:last-child,
.flx-col.flx-gap-double > *:last-child,
.flx-col.flx-gap-triple > *:last-child,
.flx-col.flx-gap-quadruple > *:last-child{margin-bottom: 0;}

.flx-row.flx-gap-half-forced > *,
.flx-dynamic.flx-gap-half-forced > *{margin-right: 8px; margin-bottom: 0;}	
.flx-row.flx-gap-default-forced > *,
.flx-dynamic.flx-gap-default-forced > *{margin-right: 16px; margin-bottom: 0;}	

.flx-row.flx-gap-half-forced > *:last-child,
.flx-row.flx-gap-default-forced > *:last-child,
.flx-dynamic.flx-gap-half-forced > *:last-child,
.flx-dynamic.flx-gap-default-forced > *:last-child,
.flx-col.flx-gap-half-forced > *:last-child,
.flx-col.flx-gap-default-forced > *:last-child{margin-right: 0;}

.flx-dynamic{display: flex; flex-direction: column;}

.flx-dynamic.flx-gap-half > *:last-child,
.flx-dynamic.flx-gap-default > *:last-child,
.flx-dynamic.flx-gap-triple > *:last-child,
.flx-dynamic.flx-gap-quadruple > *:last-child{margin-bottom: 0;}

.flx-cntr-strt{justify-content: center; align-items: flex-start;}
.flx-cntr-cntr{justify-content: center; align-items: center;}
.flx-cntr-end{justify-content: center; align-items: flex-end;}

.flx-btwn-strt{justify-content: space-between; align-items: flex-start;}
.flx-btwn-cntr{justify-content: space-between; align-items: center}
.flx-btwn-end{justify-content: space-between; align-items: flex-end;}

.flx-strt-strt{justify-content: flex-start; align-items: flex-start;}
.flx-strt-cntr{justify-content: flex-start; align-items: center;}
.flx-strt-end{justify-content: flex-start; align-items: flex-end;}

.flx-end-strt{justify-content: flex-end; align-items: flex-start;}
.flx-end-cntr{justify-content: flex-end; align-items: center;}
.flx-end-end{justify-content: flex-end; align-items: flex-end;}

.flx-dynamic-33,
.flx-dynamic-40,
.flx-dynamic-50,
.flx-dynamic-60,
.flx-dynamic-75,
.flx-dynamic-100{width: 100%;}

.flx-wrap{flex-wrap: wrap;}
.flx-wrap-grid{flex-wrap: wrap; margin: -8px}
.flx-wrap-grid > * {padding: 8px; width: 100%;}
.flx-wrap-grid.flx-wrap-grid-2-xs > *,
.flx-wrap-grid.flx-wrap-grid-2 > * {width:50%;}
.flx-wrap-grid.flx-wrap-grid-3-xs > *,
.flx-wrap-grid.flx-wrap-grid-3 > * {width:33.333%;}
.flx-wrap-grid.flx-wrap-grid-4-xs > *,
.flx-wrap-grid.flx-wrap-grid-4 > * {width: 25%;}
.flx-wrap-grid.flx-wrap-grid-5-xs > *,
.flx-wrap-grid.flx-wrap-grid-5 > * {width: 20%;}
.flx-wrap-grid.flx-wrap-grid-6-xs > *,
.flx-wrap-grid.flx-wrap-grid-6 > * {width:16.666%}
/*flx*/


.green-gradient{background: $green-base; background: $green-gradient}
.pink-gradient{background: $pink-base; background: $pink-gradient}


/*btns*/


.icon-link{display: flex; align-items: center; line-height: 1.2rem;}
.icon-link span{ position: relative;}
.icon-link svg{margin-right: 8px}
a.link{position: relative; color: $primary-font;}
a.icon-link span::before,
a.link::before{position:absolute; bottom:0; left: 0; width: 0; height: 1px; background-color: $primary-font; content: ''; @include transition(width .5s $global-ease);}
a.icon-link:hover span::before,
a.link:hover::before{width: 100%}

.icon-link.primary{color:$primary-font;}
.icon-link.primary span::before{background-color:$primary-font;}
.icon-link.accent{color:$white;}
.icon-link.accent span::before{background-color:$white;}

button,
.flat-button,
.stroked-button,
a.icon-button {font-family:"futura-pt",sans-serif; font-size: .75rem; position: relative; box-sizing: border-box; color:$primary-font; line-height: 38px; min-width:40px; background: none; display: inline-block; white-space: nowrap; text-align: center; text-decoration: none; user-select: none; cursor: pointer; outline: none; border: none; padding-left: 16px; padding-right: 16px; border-radius: $rounded-corners-buttons; @include transition(color .5s $global-ease);}
a.icon-button,
button.icon-button{padding: 0; min-width: 0; width: 40px; height: 40px; flex-shrink: 0; line-height: 40px;}
a.icon-button svg,
button svg{vertical-align: middle}

.flat-button.primary{font-weight: 700; background: $primary-font; color:$white; border: none}

.stroked-button,
a.stroked-button{ border: 1px solid #a0a0a3; color:$black; @include transition(border-color .5s $global-ease);}

.stroked-button.warn,
a.stroked-button.warn{border-color: $light-red; color: $red}

.flat-button:disabled,
.stroked-button:disabled {opacity: 0.5;}

.flat-button{z-index: 2; background: $white; color:$black; font-size:.8rem; text-transform: uppercase; border: 1px solid #a0a0a3; box-shadow: 0 2px 6px rgb(0, 0, 0, 0.2);}

.stroked-button:hover{border-color:$black}
.stroked-button.warn:hover{border-color:$red}

.master-router{min-height: calc(100vh - 160px);}

/*sidenav*/
side-nav .side-nav-content{position:fixed;z-index:9999;bottom:0;height:100%;width:40vw; background: $white; overflow-x: hidden; display: flex; flex-direction: column;}
side-nav.left .side-nav-content{left: 0; transform: translateX(-100%); @include transition(transform .7s $global-ease);}
side-nav.right .side-nav-content{right: 0; transform: translateX(100%); @include transition(transform .7s $global-ease);}
side-nav.left.open .side-nav-content, side-nav.right.open .side-nav-content{transform: translateX(0); pointer-events:auto}
.screen-overlay{position: fixed;z-index:9999; bottom: 0; left: 0; width: 100vw; height: 100vh; background-color: #0009; visibility: hidden; opacity:0; @include transition(opacity .4s $global-ease);}
side-nav.open .screen-overlay{visibility:visible; opacity: 1;}
.num_items{position: absolute; bottom: -2px; right: -2px; background:$pink; border-radius: 8px; width:16px; height: 16px; font-size: .5rem; line-height: 16px; font-weight: 700; color:$black; z-index: 5;}

.master-header-info{position: relative; z-index: 110; height:40px; width: 100%; text-transform: uppercase; font-size: 0.8rem; background-color: $background-darker; overflow: hidden;}
.master-header-info .carousel{height: 40px; width: 100%; position: absolute; transition: transform 2s; font-weight: 500;}  
.master-header-info .animated{display: flex; position: absolute; width: 100%; height: 40px; opacity:1; padding: 0 8px; justify-content: center; align-items: center; transform: translateY(100%); @include transition(transform 1s $global-ease);}
.master-header-info .animated:nth-child(1){z-index: 1; transform: translateY(0%);}

.master-header-info .ue-logo{display: none;}

.star{width: 8px; height: 8px; background: url(/assets/star_empty.svg) no-repeat center;}
.star.full{ background: url(/assets/star_full.svg) no-repeat center;}
.star.half{ background: url(/assets/star_half.svg) no-repeat center;}

.g-opinion,
.prepay,
.reserve{font-size: 0.7rem; line-height:40px; color:$primary-font; text-transform: uppercase; background: $pink-base; display: inline-block; text-align: center; position: relative; cursor: pointer;}
.prepay{background: $background-darker; color:$white}
.g-opinion{background: $light-grey; color:$primary-font}
.g-opinion .stars{margin:0 6px}
.g-opinion .star{width: 14px; height: 14px;}

.getdiscount{font-size: 0.7rem; z-index: 20; line-height:40px; height: 40px; padding: 0 16px; color:$primary-font; text-transform: uppercase; background: $green-base; display: flex; justify-content: center; align-items: center; text-align: center; position: relative; cursor: pointer;}
.getdiscount app-timer .timer{margin:0; padding: 0; line-height: 40px; font-size: 0.7rem; width: auto; align-items: center; flex-shrink: 0; margin-left: 8px;}
.getdiscount app-timer .timer .time-label{display: none;}
.getdiscount .mock-button{background: rgba(255, 255, 255, 0.2); padding: 0 8px; line-height: 24px; font-weight: 700; border-radius: 4px; margin-right: 8px;}

.top-list-wrap{position: relative;}
.top-list-wrap button{font-weight: 700; height: 28px; line-height: 24px; border-radius: 0; padding:0 0 0 6px; border:1px solid transparent}
.top-list-wrap.visible button{border:1px solid $border-default; border-bottom:1px solid $white; background: $white; position: relative; z-index: 607;}
.top-list-wrap .top-list{position: absolute; top:27px; right:0; min-width: 70px; border:1px solid $border-default; display: flex; flex-direction: column; z-index: 606; background-color: $white; text-align: right;}
.top-list-wrap .top-list a{line-height: 16px; text-align: right; padding:6px 12px; white-space: nowrap; width: 100%; border-radius: 0; font-weight: 700; font-size: 90%;}

// .sticky{position: -webkit-sticky; position: sticky; top: 0;}
// .master-header{width: 100%; z-index: 606; padding:0; height: 70px; background-color: $white; box-shadow: 0 2px 10px rgba(32, 31, 38, 0.05);}
// .master-header-wrapper{height:70px;}
// .master-header-top{width: 100%; height: 60px; position: relative; z-index: 104;}
// .master-header-right{flex:1;}
// .master-logo{position: absolute; top: 15px; left: 50%; width: 140px; height: 40px; margin: 0 0 0 -70px; padding-top:0; line-height:16px; text-indent: -999px; display: inline-block; overflow:hidden; background: url(/assets/logo.svg) center no-repeat;}
// .master-logo span{font-size: 12px}
// .master-header #basket .mat-badge-content{right: -7px; top:-7px; position: relative; z-index: 2;}
// .master-header #basket svg{position: relative; z-index: 2;}
.sticky{position: -webkit-sticky; position: sticky; top: 0;}
.master-header{width: 100%; z-index: 606; height: 70px; background-color: $white; font-size: 0.75rem;}
.master-header-wrapper{height:70px;}
.master-header-top{width: 100%; height: 60px; position: relative; z-index: 104;}
.master-header-right{flex:1;}
.master-logo{position: absolute; top: 15px; left: 50%; width: 140px; height: 40px; margin: 0 0 0 -70px; padding-top:0; line-height:16px; text-indent: -999px; display: inline-block; overflow:hidden; background: url(/assets/logo.svg) center no-repeat;}
.master-header #basket .mat-badge-content{right: -7px; top:-7px; position: relative; z-index: 2;}
.master-header #basket svg{position: relative; z-index: 2;}

.page-normal{background:$white;}

.search {position: absolute; left: 50%; transform: translateX(-50%); display: flex; border: 1px solid $border-default; background: $light-grey; min-width:40vw; z-index: 999;}
.search.hidden{display: none;}
.search.active,
.search:focus-within{border-color:$primary; box-shadow: 0 6px 18px rgb(0, 0, 0, 0.2);}
.search input{border:none; padding:0 10px; background: $light-grey; flex: 1}
.search .icon-button{margin:2.5px; color:$primary}
.search-results{position: absolute; top: 100%; left: -1px; right: -1px; overflow:hidden; padding: 0; border: 1px solid $primary; background:$white; box-shadow: 0 6px 18px rgb(0, 0, 0, 0.2);}
.search-results a{background:$white; padding: 4px 16px; border-radius: $rounded-corners; position: relative; overflow: hidden; color:$black; font-weight: 700}
.search-results a div span{font-weight: 400; opacity: 0.8;}
.search-results a app-price{margin-left: auto; margin-right: 8px;}
.search-results a::ng-deep .price{padding:5px 0 0 0; @include transition(color 1s $global-ease); text-align: left;}
.search-results hr{ width: calc(100% - 16px); height: 1px; background: $border-default;}
.search-results .mat-button-base{margin: 0 8px 8px 8px;}
.search-results-backdrop{position: fixed; top:0; left: 0; width: 100%; height: 100%; z-index: 10;}

.lang {width: 26px; height: 26px; background: $red;}

.hamburger {display: flex; justify-content: center; align-items: center; cursor: pointer;}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {width: 18px; height: 1px; background-color: $black; position: absolute; transition-property: transform; transition-duration: 0.15s; transition-timing-function: ease; }
.hamburger-inner::before, .hamburger-inner::after {content: "";display: block; }
.hamburger-inner::before {top: -5px; }
.hamburger-inner::after {bottom: -5px; }

.hamburger .hamburger-inner {transition-duration: 0.22s; transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger .hamburger-inner::before {transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
.hamburger .hamburger-inner::after {transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger.opened .hamburger-inner {transform: rotate(225deg); transition-delay: 0.12s; transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger.opened .hamburger-inner::before {top: 0; opacity: 0; transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
.hamburger.opened .hamburger-inner::after {bottom: 0; transform: rotate(-90deg); transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }


.master-drawer{overflow: hidden;}
.master-drawer .mobile-navigation-wrap{position: relative; width: 100%; margin: 0; flex: 1; overflow-y: scroll; overflow-x: hidden; background: $light-grey;}

.master-drawer .navigation-title{position: absolute; top: 5%; width: 100%; text-align: center; font-size: .75rem; opacity: .6;}
.master-drawer .signet{position: absolute; bottom: 5%; width: 100%; text-align: center; transform: translateY(0) rotate(0); opacity: 1; @include transition(transform 1s $global-ease, opacity 1s $global-ease);}
.master-drawer .signet.out{transform: translateY(50px) rotate(90deg); opacity: 0;}

.master-drawer ul{position: absolute; top: 0; min-height: 100%; left: 0; width: 100%; list-style-type: none; padding: 0 8px; @include transition(left .5s $global-ease, visibility .5s $global-ease, max-height .5s $global-ease); display: flex; flex-wrap: wrap; justify-content: center; align-content:center; overflow: hidden;}
.master-drawer ul.active{visibility: visible; max-height: 9999px; overflow: visible;}
.master-drawer ul.active.out{overflow: visible;}
.master-drawer ul ul {z-index:2; left: 100%; visibility: hidden; max-height:0; justify-content: flex-start; align-content: flex-start;}
.master-drawer ul li {width: 100%; height: auto; margin: 0; padding: 0;}
.master-drawer ul ul li {width: 50%; padding: 8px;}
.master-drawer ul li > button,
.master-drawer ul li > a{display: flex; justify-content: center; align-items: center; flex-direction: column; cursor: pointer; position: relative; color: $primary-font; padding: 0; margin: 0; min-height: 40px; width: 100%; height: 100%; white-space: normal; overflow: hidden;}
.master-drawer ul li span {text-align: center; padding: 16px 8px; z-index: 1; display: flex; justify-content: center; align-items: center;}
.master-drawer ul li span {font-size: 1rem; line-height: 1rem; text-transform: uppercase;}
.master-drawer ul ul li span {font-size: .75rem;}

.master-drawer ul .nav-header{position: sticky; top: 0; left: 0; padding: 8px; width: 100%; background: $light-grey; z-index: 2;}
.master-drawer ul .nav-header-info {margin-bottom: 8px; padding: 8px 0}
.master-drawer ul .nav-header-info span{padding:2px 0;}
.master-drawer ul .nav-header-info span:first-child{ font-size: .75rem; opacity: .5;}

.master-drawer ul .smol{display: flex; flex-direction: row; width: 50%; opacity: 1; transform: translateY(0); height: 40px;}
.master-drawer ul button.smol{align-items: center; flex-direction: row; justify-content: center; box-shadow: none; background: $white; }
.master-drawer ul a.smol{align-items: center; flex-direction: row; justify-content: center; box-shadow: none; background: $white; border: 1px solid $border-darker}
.master-drawer ul a.smol span,
.master-drawer ul button.smol span{font-size: .75rem; padding: 0; text-transform: uppercase;}

.master-drawer ul li .img-wrap{display: none}
.master-drawer ul ul li .img-wrap{display: inline-block; width: 100%; height: auto; width: 70px; max-width: 70px; overflow: hidden; padding: 0;}
.master-drawer ul li img{width: 100%; height: auto;}

.master-drawer  ul > li > a::after{position: absolute; bottom: 0; width: 60%; height:2px; background: $border-default; content:''}
.master-drawer  ul ul > li > a::after{width: 100%;}
.master-drawer .mobile-navigation-wrap > ul > li:last-child > a::after{display: none;}

.master-drawer ul li a,
.master-drawer ul li button{opacity: 0; transform: translateY(50px); transition-property: opacity, transform; transition-duration: 500ms; transition-timing-function: $global-ease;}
.master-drawer ul.active.out > li > a,
.master-drawer ul.active.out > li > button{opacity: 0; transform: translateY(-50px);}
.master-drawer ul.active > li > a,
.master-drawer ul.active > li > button {opacity: 1; transform: translateY(0);}

.master-drawer .mobile-footer{background: $white; color:$primary-font; text-align: center; padding: 16px 0 0 0;}
.master-drawer .mobile-footer span{font-size: .75rem; text-transform: uppercase; margin-bottom: 8px;}
.master-drawer .mobile-footer .social{display: flex; width: 100%; justify-content: space-around;}
.master-drawer .mobile-footer .social .icon{width:30px; height:30px; color:$primary-font}
.master-drawer .mobile-footer-contact{margin-top: 16px; padding: 16px; background:$background-darker; color:$white}
.master-drawer .mobile-footer-contact a,
.master-drawer .mobile-footer-contact span{font-size: 0.85rem; margin:0; padding: 0; color:$white}
.master-drawer .mobile-footer-contact a {font-weight: 700; margin-right: 8px;}

.breadcrumb-wrap{position: relative; z-index: 2; background: $white; width: 100%; padding-top:10px; height:20px; overflow: hidden; text-align: center; ;}
.breadcrumb{list-style-type: none; padding:0; margin:0; height: 20px; line-height: 20px; white-space:nowrap; overflow:hidden; -webkit-overflow-scrolling: touch;}
.breadcrumb li {display: inline-block; margin: 0 6px 0 0}
.breadcrumb li span,
.breadcrumb li a{height: 20px; font-size:0.7rem; text-transform: uppercase; line-height: 20px; color:$secondary; opacity: .6;}
.breadcrumb li svg {vertical-align: middle; opacity: .3; font-size: 60%; margin-left: 5px;}
.breadcrumb li:last-child span,
.breadcrumb li:last-child a{color:$secondary; opacity: 1;}
.breadcrumb li:last-child svg{display: none;}

.box-16-9-wrap{position: relative; width: 100%;}
.box-16-9{position: relative; width: 100%; z-index: 4; line-height: 0; overflow: hidden; }
.box-16-9::after{content:''; width: 100%; padding-top: 56.25%; display: inline-block;}

body header{position: relative; z-index: 5; background: $white; border-bottom: 1px solid $border-default; padding:32px 0 16px 0; margin:0 $primary-gutter 32px $primary-gutter}
body header .title-intro{margin: 0; color:$classica2; font-size:90%; text-transform: uppercase;}
body header p{margin-bottom:16px}
body header h1{text-align: center;}
body header h1:empty::before {content:""; display:inline-block;}
body header h2{text-align: center;}
body header h2:empty::before {content:""; display:inline-block;}

.hero-icon{position: absolute; width:22px; height: 23px; left: 50%; bottom: -10px; background-color: $white; margin-left: -12px; border-radius: 11px;}
.hero-icon span{position: absolute; width: 12px; height: 12px; border-radius: 100%; border:1px solid $border-default; top: 7px; left: 0; content:'';}
.hero-icon span:last-child{top:2px; left: 5px;}
.hero-icon span::after{position: absolute; top: -1px; left: 9px; width: 10px; height:10px; border-radius: 100%; border:1px solid $border-default; content:'';}
.hero-icon span:last-child::after{left: -1px; top: 9px}
// .hero-icon::before{position: absolute; width: 10px; height:10px; border-radius: 100%; border:2px solid $red; bottom: 0; left: 100%; content:''}
// body header::before{position: absolute; width: 10px; height:10px; border-radius: 100%; border:2px solid $black; bottom: 0; right: 50%; content:''}

.badges{z-index:2}
.badges .badge{display: inline-block; font-size: .65rem; padding:2px 5px; margin-left: 2px; color: $primary-font; min-width: 30px; height: 20px; line-height: .65rem; text-align: center; @include border-radius($rounded-corners-buttons); position: relative; overflow: hidden;}
.badges .badge.fastdelivery.red{color:#d30000; font-weight: 700;}
.dummy-card{position: relative; width: 100%; height:0; padding-bottom: 150%; background:$white; position: relative; border: 1px solid $border-default; @include border-radius($rounded-corners);}
.low-stock{background:$black; color:$white; @include border-radius($rounded-corners); padding:4px; margin: 4px 0; text-align: center;}

app-product {display: flex; flex-direction: column; position: relative; padding: 0; min-height: 100%; overflow: hidden;}
app-product .badges{position: absolute; bottom:8px; right:8px; display: flex; flex-direction: row;}
app-product .product-image img.product-icon{display: inline-block; height: 14px; width: auto; margin: 0 4px 0 0; vertical-align: -2px; position: relative;}
app-product .attricon .product-icon{display: inline-block; height: 14px; margin:0 0 8px 0;}
app-product .addtocart{position: absolute; bottom:8px; left:8px; z-index: 2;}
app-product a{color:$primary-font}
app-product .product-image{position: relative; width: 100%; height:0; padding-bottom: 120%; @include border-radius(6px); overflow: hidden; background: $light-grey;}
.slider-cell app-product picture img,
app-product .product-image img{position: absolute; top:50%; left:0; transform: translateY(-50%); width:100%; height:auto; object-fit: contain; mix-blend-mode: multiply;}
app-product .card-product-content-wrap{position: relative; background-color: $light-grey; display: flex; flex-grow: 1;  background: $white;}
app-product .card-product-content{width: 100%; color:$secondary; padding: 8px 0 0 0; margin:0; display: flex; flex-direction: column; justify-content: flex-start; z-index: 2; background-color: transparent; @include transition(background-color .4s $global-ease);}

app-product .card-product-actions{display: flex; flex-direction: column; padding:0 16px 16px 16px; background-color: $light-grey;}
app-product .card-product-actions > div{display: flex;}

.card-discount{width: 100%; padding: 16px; border-radius: $rounded-corners-buttons; position:relative; text-align: center; flex-shrink: 0;}
.card-discount .flat-button{margin:8px}
.card-discount h3{font-family: 'futura-pt'; font-size:1rem; font-weight: 700; background-color: none; text-align: center;}

app-price .price i{display: none;}

app-product app-price{position: relative; width: 100%; flex-direction: column; align-items: center;}
app-product app-price .price{display: flex; align-items: center; justify-content: space-around; width: 100%; text-align: center;}
app-product app-price .price b{font-size: 0.8rem; color:$primary-font;}
app-product app-price .price-old {display: none}
app-product app-price .price i{text-decoration: none; font-size: .65rem; color:#B1AFB6; font-style: normal; display: inline-block;}
app-product app-price .lastprice{font-size: 80%; opacity: 0.8;}
app-product h3{font-family:"futura-pt",sans-serif; font-size: 1rem; line-height: 100%; font-weight: 400; color: $primary-font; text-align: center; text-transform: initial; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; margin: 12px 8px 8px 8px; }
app-product h4{font-family:"futura-pt",sans-serif; font-size: 0.65rem; line-height: 100%; font-weight: 400; text-transform: uppercase; color: #B1AFB6; text-align: center; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; margin-bottom: 12px; }
app-product p{font-size: 0.7rem; font-weight: 400; color:$classica2; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}

app-price{display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start; font-size: .9rem; line-height: 1rem;}
app-price .price-old {font-size: 80%; opacity:0.6; display: flex; flex-direction: column; justify-content: center; align-items: flex-start;}
app-price .price span.normal,
app-price .price-old span.normal{text-decoration: line-through; opacity: 0.6;}


app-price .price-withcode{display: none};
app-product app-price .price-withcode{display: flex; flex-direction: row; justify-content: flex-start; width: 100%; text-align: center; font-size: .75rem; color:$white; padding: 0; margin-top: 12px; background:$background-darker}
app-product app-price .price-withcode b{font-size: .9rem; color: $primary-font; margin:0; height: 40px; width: 40px; line-height: 40px; background: $classica1;}
app-product app-price .price-withcode .flx-col{padding:3px 12px; text-align: left;}
app-product app-price .price-withcode i{font-size: .65rem; line-height: 16px; font-style: normal;}
app-product app-price .price-withcode span{font-size: .9rem; font-weight: 700; line-height: 16px; font-style: normal;}

app-product app-price.special-promo .price-withcode{background: #F0D8C7; color:#201F25}
app-product app-price.special-promo .price-withcode b{background:#BB0211; color:$white}
app-product .badge.special-promo{background:#BB0211; color:$white; position: absolute; top: 8px; right: 8px; font-weight: 700; font-size: .6rem; padding: 4px 8px; border-radius: $rounded-corners-buttons;}
app-product .badge.special-promo img{position: relative; width: 16px; height:16px; margin-right: 4px; transform: translateX(0); mix-blend-mode: normal; vertical-align: -5px;}

.attricons{position: absolute; top:16px; left:16px; z-index: 3; font-size: 7pt; line-height: 7pt; text-align: center;}
.attricons svg{height: 14px; margin-bottom: 4px;}
.favorite .span{background-color: $white; line-height: 0; border-radius: 100%;}
.icon-heart-o{fill:$white}

app-product .favorite{position: absolute; top:8px; right:8px; z-index: 1; display: flex; justify-content: center; align-items: center;}
app-product .favorite .checkbox{background-color: $white; width: 40px; height:40px; padding: 12px; cursor: pointer; border-radius: 20px;}
app-product .favorite .checkbox input{background-color: $grey; width: 16px; height: 16px; border-radius: 8px; margin:0; -webkit-appearance: none; -moz-appearance: none; appearance: none;}
app-product .favorite .checkbox input:checked {background-color: $background-darker;}
app-product .favorite .icon-button{background-color: $white; line-height: 0; border-radius: 100%;}

.loading-content{position: relative; overflow: hidden; background-color: $grey; border-radius: 4px; width: 100%;}
.loading-content::after {content: ''; display: block; position: absolute; left: -33.33333%; top: 0; height: 100%; width: 33.33333%; background: linear-gradient(to right, transparent 0%, $white 50%, transparent 100%); animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;}
@keyframes load {from {left: -33.33333%;} to{left: 100%;}}

.input-quantity{background-color: $pink; border: none; min-width:42px; width:42px; height:42px; margin: 0 4px; flex:1 1 auto; text-align: center; font-weight: 700; vertical-align: bottom; @include border-radius(100%);}
.stroked-button.btn-quantity{padding:0; background:$white;  @include border-radius(100%); min-width:42px; width: 42px; height: 42px; flex: 0 1 auto; border: none; color:$black;}
.btn-delete{line-height: 54px; color:$red; padding:0; height: 42px; min-width: 42px !important; margin-left: 6px; background: $white; border: none; @include border-radius(100%);}
.btn-delete svg{vertical-align: 1px;}
/*dialog without padding*/

body.disabled-modal modal-dialog{position: fixed; top:0; left: 0; width: 100%; height: 100%; z-index: 9999; display: flex; justify-content: center; align-items: flex-start; overflow-y: scroll; padding: 32px 0;}
modal-dialog .dialog{position: relative; background:$white;  @include border-radius($rounded-corners-buttons); box-shadow: 0 16px 32px rgba(0,0,0,0.15), 0 8px 12px rgba(0,0,0,0.10); max-width: 96vw; padding:16px; margin: auto 0; z-index: 9999; @include transition(opacity .4s $global-ease);}
modal-dialog .dialog.code{transform: translateY(30px);}
modal-dialog .dialog.narrow{max-width: 650px; width: 90%}
modal-dialog .dialog.narrow h3{font-family:"futura-pt",sans-serif; font-weight: 700; margin-bottom: 8px;}
modal-dialog .dialog.narrow .flx-dynamic{margin-top: 16px}
modal-dialog .dialog-buttons{display: flex; justify-content: flex-end; align-items: center;}
modal-dialog .dialog .dialog-buttons{ margin-top:16px}
modal-dialog .dialog-buttons > * {margin-right: 16px;}
modal-dialog .dialog-buttons > *:last-child {margin-right: 0;}
modal-dialog .close-abs{position: absolute; top: 16px; right: 16px; width: 40px; height: 40px; border: 1px solid $border-default; border-radius: 20px; text-align: center; padding: 0; line-height: 1px;}

modal-dialog .confirmbox{border-top: 1px solid $border-default; padding: 0;}
modal-dialog .confirmbox h5{font-family:"futura-pt",sans-serif; text-transform: none; font-weight: 400;}
modal-dialog .confirmbox.first{margin-top: 16px;}
modal-dialog .confirmbox.last{border-bottom: 1px solid $border-default; margin-bottom: 8px;}
modal-dialog .confirmbox .icon-button{border: 1px solid $border-default; border-radius: 30px; margin: 3px 6px 3px 0}

modal-dialog .snackbar{position: fixed; bottom: 16px; left: 50%; background: $white; color:$black; transform: translateX(-50%); display: flex; justify-content: space-between; align-items: center; border: 1px solid $light-grey; @include border-radius($rounded-corners-buttons); box-shadow: 0 16px 32px rgba(0,0,0,0.15), 0 8px 12px rgba(0,0,0,0.25); min-width: 300px; max-width: 96vw; padding:8px 8px 8px 16px; z-index: 9999; @include transition(opacity .4s $global-ease);}
// modal-dialog .snackbar.success{ border-color:$green; }
// modal-dialog .snackbar.success button{ border-color:1px solid $green; }
// modal-dialog .snackbar.warning{ border-color:$red; }
// modal-dialog .snackbar.warning button{ background:$red; }
modal-dialog .snackbar .dialog-buttons{margin-left: 16px;}

modal-dialog .screen-overlay.visible,
modal-dialog .snackbar,
modal-dialog .dialog.visible{opacity: 1; visibility: visible;}

.checkmark__circle{stroke-dasharray: 166;stroke-dashoffset: 166;stroke-width: 2;stroke-miterlimit: 10;stroke: $green;fill: none;animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards}
.checkmark{min-width:40px; width: 40px;height: 40px;border-radius: 50%;display: block;stroke-width: 2;stroke: #fff;stroke-miterlimit: 10;margin-right: 16px; box-shadow: inset 0px 0px 0px $green;animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}
.checkmark__check{transform-origin: 50% 50%;stroke-dasharray: 48;stroke-dashoffset: 48;animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards}

.normal .checkmark__circle{stroke: $orange}
.normal .checkmark{box-shadow: inset 0px 0px 0px $orange;animation: fillwarn .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}

.error .checkmark__circle{stroke: $red}
.error .checkmark{box-shadow: inset 0px 0px 0px $red;animation: fillerror .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}

@keyframes stroke{100%{stroke-dashoffset: 0}}
@keyframes scale{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}}
@keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px $green}}
@keyframes fillerror{100%{box-shadow: inset 0px 0px 0px 30px $red}}
@keyframes fillwarn{100%{box-shadow: inset 0px 0px 0px 30px $orange}}

.nopad-dialog .mat-dialog-container{padding:0}
.nobg-dialog .mat-dialog-container{background: none; padding: 0; overflow: hidden; box-shadow: none}
.mat-dialog-container .dialog-close{position: absolute; top:-14px; right:-14px; border: 1px solid $border-default; background-color: $white; z-index: 9; line-height: 38px; @include border-radius($rounded-corners-buttons);}
.custom-dialog-container{overflow: scroll; max-height: 90vh;}

/*master loader*/
#master-loader-bar.loader{position: fixed; top: 0; left: 0; width:100vw; max-width: 100vw; height:2px; background:$light-green; z-index: 99999999999;}

/*lists*/
.blist li{margin: 5px 0 5px 25px; display: inline-block; position: relative; width: 100%;}
.blist li::before{position: absolute; top:50%; left: -17px; transform: translateY(-50%); width:6px; height: 6px; border-radius: 3px; background: $primary; content:''}

.list-image img{height: 40px; margin:5px}

/*dynimage*/
picture{display: block; line-height: 0;}
app-image{display: flex; flex-direction: column;}
.app-image-caption{font-size: 1rem; line-height: 1rem; padding: 8px 0 0 0; font-weight: 300; font-style: italic; position: relative;}
.dummy-img {position: absolute; top:0; left:0; width: 100%; height: 100%; overflow: hidden;}
.dummy-img::after{position: absolute; top:0; left: 0; width:100%; height: 100%; content:''}
.dummy-img img{position: absolute; top:0; left:0; width: 100%; height:100%; object-fit: cover;}
.dummy-img.natural{position: relative; display: inline-block;}
.dummy-img.natural img{position: relative; top: auto; height: auto;}

.bggrey{background-color: $grey;}

/*radio checkbox form field mimic*/
.mat-error{white-space: nowrap; color:$red}
.radio-checkbox-wrap{margin: .25em 0; padding-bottom:1.34375em}
.mat-error.custom,
.rodo-checkbox .mat-error,
.radio-checkbox-wrap .mat-error{padding:0 1em; font-size:75%}

.mdc-white .mat-mdc-text-field-wrapper{background: $white;}
.mdc-notched-outline > * {border-color: $border-default !important; border-width: 1px !important;}
.mdc-text-field--focused .mdc-notched-outline > * {border-color: $border-darker !important;}
.mdc-text-field--invalid .mdc-notched-outline > * {border-color: $border-darker !important;}

// .noborder .mdc-notched-outline > * {border: none}


.social a{font-size:16px; color:$black}
.social span{display: none}
.social .icon{width:1.5em; height:1.5em; vertical-align: middle;  @include transition(color .6s $global-ease);}
.social a:hover .icon{color: $primary}

body map-info-window h4{margin:0 0 12px 0; min-width: 200px;}
body map-info-window p{margin:0 0 6px 0;}
body map-info-window p a{color:$black; text-decoration: underline;}

.rodo-checkbox{margin-bottom:14px; font-size: 12px; line-height: 16px;}
.rodo-checkbox .mat-checkbox-layout .mat-checkbox-label{white-space: normal; font-size: 12px; line-height: 16px;}

#fb-messenger-fake-button {padding: 0 !important; border-radius: 50%; width: 50px; height: 50px; border:none; box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); cursor: pointer; transition: all 0.3s; overflow: hidden; background: transparent;}
#fb-messenger-fake-button svg {display: block;  width: 100%; height: 100%;}
#fb-messenger-fake-button.active #logo {opacity: 0;}
#fb-messenger-fake-button.active #spinner {animation: spin 3s linear infinite; transform-origin: center;}
@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

@media screen and (min-width: 768px) {

  // side-nav .side-nav-content{position:fixed;z-index:9999;bottom:0;height:100%;width:40vw; background: $white; overflow-x: hidden; display: flex; flex-direction: column;}
 
  // photo-dialog .photo-dialog-body{overflow-x: hidden; overflow-y: scroll;}
  // photo-dialog .photo-dialog-body img{height: auto; width: 100%}
  .hide-xs{display: inline-block;}
  .w-33-md{width: 33.333%;}
  .w-50-md{width: 50%;}

  .flx-dynamic{flex-direction: row;}

  .flx-wrap-grid{flex-wrap: wrap; margin: -8px}
  .flx-wrap-grid > * {padding: 8px}
  .flx-wrap-grid.flx-wrap-grid-2-sm > * {width:50%}
  .flx-wrap-grid.flx-wrap-grid-3-sm > * {width:33.333%}
  .flx-wrap-grid.flx-wrap-grid-4-sm > * {width:25%;}
  .flx-wrap-grid.flx-wrap-grid-5-sm > * {width:20%}
  .flx-wrap-grid.flx-wrap-grid-6-sm > * {width:16.666%}

  .flx-cntr-strt-md{justify-content: center; align-items: flex-start;}
  .flx-cntr-cntr-md{justify-content: center; align-items: center;}
  .flx-cntr-end-md{justify-content: center; align-items: flex-end;}

  .flx-btwn-strt-md{justify-content: space-between; align-items: flex-start;}
  .flx-btwn-cntr-md{justify-content: space-between; align-items: center}
  .flx-btwn-end-md{justify-content: space-between; align-items: flex-end;}

  .flx-strt-strt-md{justify-content: flex-start; align-items: flex-start;}
  .flx-strt-cntr-md{justify-content: flex-start; align-items: center;}
  .flx-strt-end-md{justify-content: flex-start; align-items: flex-end;}

  .flx-end-strt-md{justify-content: flex-end; align-items: flex-start;}
  .flx-end-cntr-md{justify-content: flex-end; align-items: center;}
  .flx-end-end-md{justify-content: flex-end; align-items: flex-end;}

  .flx-row.flx-gap-half > *,
  .flx-dynamic.flx-gap-half > *{margin-right: 8px; margin-bottom: 0;}	
  .flx-row.flx-gap-default > *,
	.flx-dynamic.flx-gap-default > *{margin-right: 16px; margin-bottom: 0;}	
  .flx-row.flx-gap-double > *,
	.flx-dynamic.flx-gap-double > *{margin-right: 32px; margin-bottom: 0;}
  .flx-row.flx-gap-triple > *,
	.flx-dynamic.flx-gap-triple > *{margin-right: 48px; margin-bottom: 0;}
  .flx-row.flx-gap-quadruple > *,
	.flx-dynamic.flx-gap-quadruple > *{margin-right: 64px; margin-bottom: 0;}

	.flx-row.flx-gap-half > *:last-child,
  .flx-dynamic.flx-gap-half > *:last-child,
	.flx-row.flx-gap-default > *:last-child,
  .flx-dynamic.flx-gap-default > *:last-child,
	.flx-row.flx-gap-double > *:last-child,
	.flx-dynamic.flx-gap-double > *:last-child,
	.flx-row.flx-gap-triple > *:last-child,
  .flx-dynamic.flx-gap-triple > *:last-child,
  .flx-row.flx-gap-quadruple > *:last-child,
  .flx-dynamic.flx-gap-quadruple > *:last-child{margin-right: 0; margin-bottom: 0;}

	// .flx-wrap-grid .flx-dynamic-50{width: calc(50% - 8px); margin-bottom: 16px;}
	// .flx-wrap-grid .flx-dynamic-50:nth-child(even){margin-right: 0;}

	.flx-dynamic-33{width: 33.333%}
	.flx-dynamic-40{width: 40%}
	.flx-dynamic-50{width: 50%;}
	.flx-dynamic-60{width: 60%}
	.flx-dynamic-75{width: 75%;}
	.flx-dynamic-100{width: 100%;}


  app-price .price-old span.info{display: inline-block; color:$primary-font; opacity: 0.6;}

  app-product .card-product-actions{flex-direction: row;}
  app-product .card-product-actions .flat-button{flex: 1 0 auto; margin-left: 6px;}
  app-product .icon-button svg{transform: scale3d(1,1,1); @include transition(transform .2s $global-ease)}
  app-product .icon-button:hover svg{transform: scale3d(1.3,1.3,1.3);}


  .breadcrumb li a:hover{text-decoration: underline; opacity: 1;}
  .flat-button{@include transition(border-color .5s $global-ease);}
  .flat-button:hover{color:$black; border-color: $white;}
  .flat-button.primary:hover{color:$white; border-color: $black;}
  .flat-button.warn:hover{color: $red;}
  .flat-button.promo:hover{color: $black;}

  .attricons{font-size: .65rem; line-height: .65rem;}
  .attricons svg{height: 14px; margin-bottom: 4px;}
}

@media screen and (min-width: 1280px) {

  .master-header-info .ue-logo{height: 40px; width: auto; position: fixed; top: 0; right: 0; z-index: 5; display: block;}
  .master-header-info .ue-logo img{height: 40px; width: auto;}

  .hide-sm{display: inline-block;}
  .hide-md{display:none !important}

  .flx-wrap-grid{flex-wrap: wrap; margin: -8px}
  .flx-wrap-grid > * {padding: 8px}
  .flx-wrap-grid.flx-wrap-grid-2-md > * {width:50%}
  .flx-wrap-grid.flx-wrap-grid-3-md > * {width:33.333%}
  .flx-wrap-grid.flx-wrap-grid-4-md > * {width:25%;}
  .flx-wrap-grid.flx-wrap-grid-5-md > * {width:20%}
  .flx-wrap-grid.flx-wrap-grid-6-md > * {width:16.666%}


  .paginator{flex-direction: row;}

  side-nav.left .side-nav-content{transform: translateX(0); position: relative; z-index: 10; width: 100%; height: auto !important; overflow: visible; box-shadow: none;}
  .master-drawer .signet,
  .master-drawer .nav-header,
  // .master-drawer  ul > li > a::after,
  .master-drawer .mobile-navigation-wrap > ul > li > a::after,
  side-nav.left .side-nav-content .mobile-footer{display: none;}

  .master-drawer .mobile-navigation-wrap{overflow: visible;}
  .master-drawer ul{position: relative; flex-wrap: nowrap; justify-content: center; padding:0; left: 0 !important; background:$white}
  .master-drawer ul li{padding: 0; width: auto; z-index: 1;}
  .master-drawer ul li.smol{display:none}
  .master-drawer ul ul li{width: auto; margin: 0 8px; flex: 1 1 100%; max-width: 9%;}
  .master-drawer ul ul ul li{width: auto;}
  .master-drawer ul li span{font-size: .75rem; min-height: 40px; width:100%; padding: 8px 16px; line-height: 22px; position: relative;}

  .master-drawer ul ul li .img-wrap{display: none;}
  .master-drawer ul ul ul li .img-wrap{display: inline-block;}
  
  .master-drawer ul li > a{ width: 100%; height: 100%; background: none; display: flex; justify-content: space-around;}
  .master-drawer ul li > a.active_item,
  .master-drawer ul li > a:hover{background-color: $white;}
  .master-drawer ul.active.out > li > a{opacity: 1; transform: translateY(0px);}

  .master-drawer ul ul {position: absolute; top:100%; max-height: initial; justify-content: center; padding: 16px $primary-gutter 0 $primary-gutter; opacity: 0; transform: translateY(0); @include transition(opacity 1s $global-ease, visibility 1s $global-ease); background:$light-grey}
  .master-drawer ul ul.active{opacity: 1; transform: translateY(0);}
  // .master-drawer ul ul::after{position: absolute; bottom:0; left: $primary-gutter; right: $primary-gutter; height: 1px; background: $white; content:''}

  .navigation-backdrop{background:$grey; position: absolute; top: 100%; width: 100%; @include transition(height .25s $global-ease); box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;}

  .master-header-drop{position: absolute; top: 100%; width: 100%; height: auto; left: 0; background: $white; padding: 32px 16px 16px 16px; overflow: hidden; z-index: 103; transform: translate3d(0, -150%, 0); @include transition(transform .5s $global-ease);}
  .master-header-drop.visible{transform: translate3d(0, 0, 0); visibility: visible;}
  .master-header-drop ul{list-style-type: none; padding:0; margin:0; width: 100%; display: flex; justify-content: center; align-items: flex-start;}
  .master-header-drop-main.multilevel{display: none;}
  
  .master-header-drop .master-header-drop-main{background: $white;}
  .master-header-drop .master-header-drop-main li.active{background: $white; box-shadow: 1px 0px white}

}

@media screen and (max-width: 1279px) {
  side-nav .side-nav-content{width:100vw; max-width: 520px;}
  // body .master-drawer{display:flex; min-height:100%; padding: 0; overflow: hidden; background: $background}

  .gutter{padding-left: 16px; padding-right:16px}
  .gutter-v{padding-top: 24px; padding-bottom:24px}
  .gutter-t{padding-top: 24px}
  .gutter-b{padding-bottom: 24px}

  .master-header-drop{left: 0%; z-index: 2;}
  .master-router{min-height: calc(100vh - 110px);}

  // .master-logo{top: 5px; left: 16px; width: 100px; padding-top:58px; line-height:14px; font-size: 28px; font-weight: bold;}

  .master-drawer-inner-wrap{min-height: 100%; padding:60px 0 0 0}

  .master-header-right{margin-left: 116px}
  .master-header-right .hamburger{margin-right: auto;}
  
  #navbar-footer{width:100%; padding: 0 $primary-gutter;}
  #navbar-footer .social{color:$black}
  #navbar-footer::ng-deep p{font-size:14px; font-weight: 400}
  #navbar-footer-bottom {margin:40px 0}

  .search{top:100%; width: 100vw;}
  .getdiscount > span{display: inline-block; line-height: 14px; vertical-align: middle; margin-right: 12px; text-align: right;}

}


@media screen and (max-width: 599px) {

  .flx-break{flex-basis: 100%; height: 0;}

  .flx-col.flx-gap-half > *,
  // .flx-row.flx-gap-half > *
  .flx-dynamic.flx-gap-half > *{margin-bottom: 8px;}
  .flx-col.flx-gap-default > *,
  // .flx-row.flx-gap-default > *,
  .flx-dynamic.flx-gap-default > *{margin-bottom: 16px;}
  .flx-col.flx-gap-double > *,
  // .flx-row.flx-gap-double > *,
  .flx-dynamic.flx-gap-double > *{margin-bottom: 18px;}
  .flx-col.flx-gap-triple > *,
  // .flx-row.flx-gap-triple > *,
  .flx-dynamic.flx-gap-triple > *{margin-bottom: 24px;}
  .flx-col.flx-gap-quadruple > *,
  // .flx-row.flx-gap-quadruple > *,
  .flx-dynamic.flx-gap-quadruple > *{margin-bottom: 32px;}

  .square-wrap{padding-bottom: 100%;}
  .square-wrap-small{padding-bottom: 100% !important;}

  modal-dialog .snackbar{flex-direction: column;}
  modal-dialog .snackbar .dialog-buttons{margin-left: 0; margin-top: 8px; width: 100%;}
  modal-dialog .snackbar .dialog-buttons button{flex-grow: 1;} 

  modal-dialog .dialog.narrow button.stroked-button{width: 100%;}
  modal-dialog .dialog.narrow button.flat-button{width: 100%; padding:8px}
}

.fav-heart{height: 50vh; position: relative; z-index: 1; background: $white;}
.in_favorites svg{fill: $pink; color: $pink}

$bubble-d: 4rem; // bubble diameter
$bubble-r: .5*$bubble-d; // bubble-radius
$particle-d: .375rem;
$particle-r: .5*$particle-d;

@mixin particles($k) {
  $shadow-list: ();
  $n-groups: 7;
  $hb-angle: calc(360deg/$n-groups);
  $group-distr-r: (1 + $k*.25)*$bubble-r;
  $n-particles: 2;
  $particle-base-angle: calc(360deg/$n-particles);
  $particle-off-angle: 60deg; // offset angle from radius
  $spread-r: -$k*$particle-r;
  
  @for $i from 0 to $n-groups {
    $h-angle: $i*$hb-angle - 90deg;

    $xg: calc($group-distr-r * cos($h-angle));
    $yg: calc($group-distr-r * sin($h-angle));
    
    @for $j from 0 to $n-particles {
      $particle-curr-angle: $h-angle + 
      $particle-off-angle + $j*$particle-base-angle;
      $xs: calc($xg + $particle-d * cos($particle-curr-angle));
      $ys: calc($yg + $particle-d * sin($particle-curr-angle));
      $shadow-list: $shadow-list, $xs $ys 0 $spread-r $pink;
    }
  }
  
  box-shadow: $shadow-list;
}

@mixin bubble($ext) {
  transform: scale(1);
  border-color: $pink;
  border-width: $ext;
}

[id^='toggle-heart'] {
  position: absolute;
  left: -100vw;
  z-index: 10;
  
  &:checked + label {
    color: $pink;
    will-change: font-size;
    animation: heart 1s cubic-bezier(.17, .89, .32, 1.49);

    svg {
      fill:$pink;
    }
    
    &:before, &:after {
      animation: inherit;
      animation-timing-function: ease-out;
    }
    
    &:before {
      will-change: transform, border-width, border-color;
      animation-name: bubble;
    }
    
    &:after {
      will-change: opacity, box-shadow;
      animation-name: particles;
    }
  }
}

[for^='toggle-heart'] {
  display: flex; justify-content: center; align-items: center;
  width:40px; height: 40px; border-radius: 100%; background: $white;
  align-self: center;
  position: relative;
  color: $black;
  font-size: 1rem;
  line-height: 0;
  cursor: pointer;

  
  &:before, &:after {
    position: absolute;
    z-index: -1;
    top: 50%; left: 50%;
    border-radius: 50%;
    content: '';
  }
  
  &:before {
    box-sizing: border-box;
    margin: -$bubble-r;
    border: solid $bubble-r ;
    width: $bubble-d; height: $bubble-d;
    transform: scale(0);
  }
  
  &:after {
    margin: -$particle-r;
    width: $particle-d; height: $particle-d;
    @include particles(1);
  }
}

@keyframes heart {
  0%, 17.5% { font-size: 0; }
}

@keyframes bubble {
  15% { @include bubble($bubble-r); }
  30%, 100% { @include bubble(0); }
}

@keyframes particles {
  0%, 20% { opacity: 0; }
  25% {
    opacity: 1;
    @include particles(0);
  }
}